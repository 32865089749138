import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "header-base"
    }}>{`Header base`}</h2>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe src="https://codesandbox.io/embed/v10-starter-sandbox-kf3h4?fontsize=14&hidenavigation=1&view=preview" title="header-base" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "borderWidth": "0px",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "header-with-nav"
    }}>{`Header with nav`}</h2>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe src="https://codesandbox.io/embed/header-base-v07hj?fontsize=14&hidenavigation=1&view=preview" title="header-with-nav" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "borderWidth": "0px",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "header-with-actions"
    }}>{`Header with actions`}</h2>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe src="https://codesandbox.io/embed/header-with-actions-vh014?fontsize=14&hidenavigation=1&view=preview" title="header-with-actions" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "borderWidth": "0px",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "header-with-actions-and-nav"
    }}>{`Header with actions and nav`}</h2>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe src="https://codesandbox.io/embed/header-with-actions-and-nav-m0lo2?fontsize=14&hidenavigation=1&view=preview" title="header-with-actions-and-nav" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "borderWidth": "0px",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <h2 {...{
      "id": "header-with-sidenav"
    }}>{`Header with sidenav`}</h2>
    <Row mdxType="Row">
  <Column offsetLg="4" mdxType="Column">
    <iframe src="https://codesandbox.io/embed/header-with-sidenav-1u7su?fontsize=14&hidenavigation=1&view=preview" title="header-with-sidenav" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style={{
          "width": "100%",
          "height": "500px",
          "borderWidth": "0px",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin" />
  </Column>
    </Row>
    <ComponentDocs component="ui-shell" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      